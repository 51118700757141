.footer {
    opacity: 0;
    bottom: 0;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: black;
    padding-top: 30px;
    padding-bottom: 30px;
    animation: footer-loading 1s forwards;
}

.footer .grid {
    text-align: center;
    width: auto;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-decoration: none;
    margin: 0 0 10px;
}

.footer a {
    text-decoration: none;
    color: #f5f5f7;
    display: inline-block;
}

.footer .social-media a {
    margin: 3px 10px 3px 3px;
    grid-column: 1;
    text-align: left;
}

.footer .footer-link a {
    font-weight: bold;
    grid-column: 2;
    border-right: 2px solid #f5f5f7;
    padding-right: 5px;
    padding-left: 5px;
}

.footer .footer-link a:last-child {
    border-right: none;
}
@keyframes footer-loading {
    99%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
