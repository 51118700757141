@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');
html, body {
  max-width: 100%;
  overflow-x: hidden;
}
h1, h2, a{
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #435757;
  background: #f5f5f7;

}




