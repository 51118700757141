@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');

.legal{
    padding-left: 0;
    padding-right: 0;
}

.legal .hebergement{
    margin-left: 30px;
    margin-top: -30px;
}

.legal a, .legal a:visited, .legal a:hover{
    text-decoration: underline;
    color: #435757;
    font-size: .9em;
}

@media (min-width: 800px){

    .legal{
        padding-left: 10%;
        padding-right: 10%;

    }
    .legal h1{
        font-size: 3em;
    }
    .legal h2{
        font-size: 2em;
    }
    .legal p{
        width: 700px;
        font-size: 1.5em;
    }
}
.legal-loader{
    overflow: hidden;
    z-index: 10;
    width: 100%;
    height: 0;
    position: fixed;
    top: 90px;
    left: 0;
    background-color: black;
    display: grid;
    animation: devis-loading-bg 2s forwards;
}

.legal-loader p{
    margin-left: 5%;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 10vw;
    visibility: hidden;
    z-index: 11;
    height: 0;
    animation: legal-loading-title 2s forwards;
}

.legal .content{
    opacity: 0;
    animation: devis-loading-content 1s forwards;
}



@keyframes legal-loading-content {
    99%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes legal-loading-bg {
    0%{
        height: 0;
    }
    50%{
        top: 90px;
    }
    60%{
        height: 100%;
        opacity: 1;
    }
    99%{
        z-index: 10;
    }
    100%{
        z-index: -10;
        height: 100%;
        top: 100%;
    }
}

@keyframes legal-loading-title {
    0%{
        visibility: visible;
    }
}
