@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');

.devis{
    padding: 30px;
}
.devis-loader {
    overflow: hidden;
    z-index: 10;
    width: 100%;
    height: 0;
    position: fixed;
    top: 90px;
    left: 0;
    background-color: black;
    display: grid;
    animation: devis-loading-bg 2s forwards;
}

.devis-loader p{
    margin-left: 5%;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 10vw;
    visibility: hidden;
    z-index: 11;
    height: 0;
    animation: devis-loading-title 2s forwards;
}

.devis .content{
    opacity: 0;
    animation: devis-loading-content 1s forwards;
}
.devis a{
    text-decoration: underline;
    color: #435757;
}


@keyframes devis-loading-content {
    99%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes devis-loading-bg {
    0%{
        height: 0;
    }
    50%{
        top: 90px;
    }
    60%{
        height: 100%;
        opacity: 1;
    }
    99%{
        z-index: 10;
    }
    100%{
        z-index: -10;
        height: 100%;
        top: 100%;
    }
}

@keyframes devis-loading-title {
    0%{
        visibility: visible;
    }
}
