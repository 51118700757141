.home{
    padding: 30px;
}

.home .home-choice{
    margin-top: 70px;

}
.home .first-p{
    margin-top: 50px;
}
.home .grid {
    display: grid;
    height: 150px;
    grid-template-rows: repeat(2, 1fr);
}
.sites-button{
    grid-row: 1;
}

.server-button{
    margin-top: 50px;
    grid-row: 2;
}

.sites-button .checkbox p{
    z-index: 5;
}

.home .checkbox p{
    display: inline-block;
    color: #435757;
    height: 100px;
    width: 200px;
    position: absolute;
    opacity: 0;
    margin-top: 70px;
    margin-left: 20px;
    visibility: hidden;
}
.home .checkbox .title-button{
    text-align: center;
    margin-top: 0;
    font-family: 'Open Sans', sans-serif;
}
.home .checkbox p a{
    display: block;
    margin-top :5px;
    text-decoration: underline;
    font-weight: bold;
    color: #435757;
}

.home .checkbox p a:hover, .checkbox p a:visited{
    color: #435757;
}

.home .checkbox .title-button{
    text-align: center;
    margin: 0;
    font-weight: bold;
}


.home input {
    position: absolute;
    left: -9999px;
}

.home label {
    color: #f5f5f7;
    width: 120px;
    height: 22px;
    position: absolute;
    padding: 20px 15px;
    border-radius: 10px;
    background: #282828;
    transition: all 1s;
}

.sites-button label{
    z-index: 5;
}

.close-button{
    z-index: 1;
    display: flex;
    position: absolute;
    right: 0;
    top: 5px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    opacity: 0;
    color: red;
    transition: all .5s;
}



.home input:checked + label {
    border: #435757 solid 4px;
    color: #435757;
    width: 200px;
    height: 275px;
    background: #fafafa;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5),
    0 0 0 rgba(0, 0, 0, 0.5) ;
    webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5),
    0 0 0 rgba(0, 0, 0, 0.5) ;
    moz-box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5),
    0 0 0 rgba(0, 0, 0, 0.5) ;

}

.home input:checked + .sites-button label {
    z-index: 3;
}

.home input:checked + label .close-button {
    opacity: 1;
}

.home input:checked + label::before:hover {
    background-color: blue;
}


.home input:checked + label + p{
    animation: text-apparition 1s forwards;
    visibility: visible;
}
.home input:checked + label .close-button:hover{
    text-shadow:  2px 2px 2px #bbbbbb,
    0 0 0 #ffffff,
    0 0 0 #bbbbbb,
    1px 1px 1px #ffffff;
}


@keyframes text-apparition {
    70%{
        opacity: 0;
    }
    75%{
        opacity: .3;
    }
    100%{
        opacity: 1;
    }
}

.home .img-profil{
    border: solid 2px #435757;
    border-radius: 5px;
    float: right;
    text-align: right;
    margin-top: -60px;
    height: 100px;
    width: auto;
    margin-left: 15px;
    margin-bottom: 15px;
}
.home .presentation{
    margin-top: 180px;
    margin-bottom: 70px;
}

@media (min-width: 450px) {


    .home .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .home .server-button{
        margin-top: -75px;
        grid-column: 2;
    }

    .home .sites-button{

        grid-column: 1;
    }


    .home .presentation {
        margin-top: 0;
    }
}
@media (min-width: 300px) and (max-width: 450px) {
    .home input:checked + label {
        width:65%;
    }
    .home .checkbox p{
        width:64%;
    }
}

@media (min-width: 800px) {

    .home{
        padding-left: 10%;
        padding-right: 10%;

    }
    .home .webdev h1{
        font-size: 3em;
    }
    .home .webdev h2{
        font-size: 2em;
    }
    .home .webdev p{
        width: 700px;
        font-size: 1.5em;
    }
    .home .checkbox p{
        width: 300px;
    }
    .home input:checked + label {
        width: 300px;
        height: 240px;
    }
    .presentation p{
        width: 700px;
        font-size: 1.5em;
    }
    .img-profil{
    display: none;
    }
}

.home-loader {
    opacity: 0;
    overflow: hidden;
    z-index: 100;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    display: grid;
    place-items: center;
    animation: home-loading-bg 2s forwards;
}


.home-img-loader{
    opacity: 0;
    z-index: 101;
    position: absolute;
    height: 0;
    animation: home-loading-logo 2s forwards;
}

@keyframes home-loading-bg {
    0%{
        opacity: 1;
    }
    60%{
        opacity: 1;
    }
    80%{
        z-index: 10;
    }
    95%{
        z-index: -10;
    }
    100%{
        z-index: -10;
        opacity: 0;
    }
}

@keyframes home-loading-logo {
    5%{
        height: 300px;
        opacity: 1;
    }
    25%{
        height: 200px;
    }

    50%{
        height: 300px;
    }

    60%{
        opacity: 1;
        z-index: 10;
    }
    90%{
        z-index: -10;

    }
    95%{
        height: 100000px;

    }

    100%{
        height: 100000px;
        opacity: 0;
        z-index: -10;
    }
}
