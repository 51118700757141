@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');

.site .content{
    padding: 30px;
}

.site .content h1{
    font-size: 2em;
}
.site .content h2{
    padding-top: 50px;
    margin-bottom: 40px;
    text-align: center;
    border-top : 2px solid #435757;
}
.site .content h2:first-of-type{
    border-top: none;
}
.site .content .line{
    visibility: hidden;
    margin-top: 50px;
    width: 100px;
    border-top: 2px solid #435757;
}
.site .content p{
    margin-top: 5px;
}
.site .content{
    opacity: 0;
    animation: site-loading-content 1s forwards;
    list-style: none;
}
.site .content li{
    line-height: 1.3em;
    margin-top: 20px;
}

.site .content li:before {
    content: '✓';
    padding-right: 7px ;
}

.site .content .site-contact p{;
    border-top: 2px solid #435757;
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 10px;
    font-size: 1em;
    text-decoration: none;
}
.site .content .site-contact p a{
    text-decoration: underline;
    color: #435757;
}

.site .content .site-contactp a:hover{
    font-weight: bold;
}

.site-loader {
    overflow: hidden;
    z-index: 10;
    width: 100%;
    height: 0;
    position: fixed;
    top: 90px;
    left: 0;
    background-color: black;
    display: grid;
    animation: site-loading-bg 2s forwards;
}

.site-loader p{
    margin-left: 5%;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 10vw;
    visibility: hidden;
    z-index: 11;
    height: 0;
    animation: site-loading-title 2s forwards;
}

/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;
    text-align: center;
    height: 200px;
    width: 100%;
}

.slide img{
    height: 200px;
}

/* Buttons */

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;

    background-color: #c4c4c4;
}

.slideshowDot.active {
    background-color: #435757;
}

@media (min-width: 800px) {
    .site .content{
        padding-left: 10%;
        padding-right: 10%;
    }
    .site .content .line{
        visibility: visible;
    }
    .site .content h1{
        font-size: 3em;
        text-align: unset;
    }
    .site .content h2{
        font-size: 2em;
        text-align: unset;
        border-top: unset;
        padding-top: 10px;
    }
    .site .content p{
        width: 700px;
        font-size: 1.2em;
    }
    .site .content .site-contact p{;
        font-size: 1.2em;
        border: none;
        padding-top: 0;
    }
    .slide {
        height: 400px;
    }

    .slide img{
        height: 400px;
    }

}






@keyframes site-loading-content {
    99%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes site-loading-bg {
    0%{
        height: 0;
    }
    50%{
        top: 90px;
    }
    60%{
        height: 100%;
        opacity: 1;
    }
    99%{
        z-index: 10;
    }
    100%{
        z-index: -10;
        height: 100%;
        top: 100%;
    }
}

@keyframes site-loading-title {
    0%{
        visibility: visible;
    }
}
