@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');

.header{
    background-color: black;
    padding: 35px 35px 20px;
}
.header .nav-bar{
    text-align: right;
}
.header .nav-bar a {
    opacity: .8;
    position: relative;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: #f5f5f7;
    margin-right: 50px;
    z-index: 3;
    background-color: hsla(0,0%,100%,0);
    transition: .3s;
}

.header .nav-bar a:last-child{
    margin-right: 10%;
}



.header .button {
    text-align: center;
    display: inline-block;
    position: relative;
    overflow: hidden;
    transition: background-color .3s;
    cursor: pointer;
}
a:after{
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: #222;
    transition: width .3s;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.header img{
    opacity: 0.8;
    position: absolute;
    top: 18px;
    margin-left: 10%;
    height: 60px;
}
.header img:hover{
    opacity: 1;
}
.underline {
    display: inline;
    position: relative;
    overflow: hidden;
}
.underline:after {
    content: "";
    position: absolute;
    z-index: -1;
    right: 0;
    width: 0;
    background: white;
    height: 2px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.underline:hover:after,
.underline:focus:after,
.underline:active:after {
    left: 0;
    right: auto;
    width: 100%;
}

.header .menu{
    display: none;
}
/*hamburger menu*/

.hamburger-menu{
    visibility: hidden;
}
#menu__toggle {
    opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
    right: 0 !important;
}
#menu__toggle:checked + .menu__btn{
    position: fixed;
}
.menu__btn {
    position: absolute;
    top: 35px;
    right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 11;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
    top: 10px;
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #cbcbcb;
    transition-duration: .25s;
}
.menu__btn > span::before {
    content: '';
    top: -8px;
}
.menu__btn > span::after {
    content: '';
    top: 8px;
}
.menu__box {
    z-index: 10;
    display: block;
    position: fixed;
    top: 0;
    right: -100%;
    width: 150px;
    height: 100%;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #000000;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
}
.menu__item {
    display: block;
    padding: 12px 24px;
    font-family: 'Open Sans', sans-serif;
    color: #cbcbcb;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
}


@media  screen and (max-width: 550px) {
    .header img{
        margin-left: 5%;
    }
    .header img{
        margin-left: 0;
    }
    .header .nav-bar a {
        margin-right: 15px;
    }
    .header .nav-bar a:last-child{
        margin-right: 0;
    }
}

@media screen and (max-width: 450px){
    .header .nav-bar{
        visibility: hidden;
    }
    .hamburger-menu{
        visibility: unset;
    }
}
