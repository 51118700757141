@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');
.content {
    padding: 30px;
}

.contact .game {
    position: relative;
    overflow-x: scroll;
    width: 120%;
    height: 400px;
    margin: 50px -30px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.contact .game::-webkit-scrollbar{
    display: none;
}

.contact .game #map{
    height: 100%;
    transition: all 3s;
    position: absolute;
}

.contact h2{
    margin-top: 50px;
}

.contact .content p a{
    text-decoration: underline;
    color: #435757;
}

.contact .content p a:hover{
    font-weight: bold;
}

.contact-loader {
    overflow: hidden;
    z-index: 10;
    width: 100%;
    height: 0;
    position: fixed;
    top: 90px;
    left: 0;
    background-color: black;
    display: grid;
    animation: contact-loading-bg 2s forwards;
}

.contact-loader p{
    margin-left: 5%;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 10vw;
    visibility: hidden;
    z-index: 11;
    height: 0;
    animation: contact-loading-title 2s forwards;
}

.contact .content{
    opacity: 0;
    animation: contact-loading-content 1s forwards;
}
@keyframes contact-loading-content {
    99%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes contact-loading-bg {
    0%{
        height: 0;
    }
    50%{
        top: 90px;
    }
    60%{
        height: 100%;
        opacity: 1;
    }
    99%{
        z-index: 10;
    }
    100%{
        z-index: -10;
        height: 100%;
        top: 100%;
    }
}

@keyframes contact-loading-title {
    0%{
        visibility: visible;
    }
}

@media screen and (max-width: 600px) {
    .contact .game{
        height: 200px;
    }
}

